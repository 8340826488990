import React from "react";

export default function ErrorPage() {
  return (
    <div className="error-container">
      <h1>404 - File or directory not found.</h1>
      <h4>
        The resource you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </h4>
    </div>
  );
}
